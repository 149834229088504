import React from 'react'
import Img from 'gatsby-image'
import calculateAspectRatio from 'calculate-aspect-ratio'
import { Base64 } from 'js-base64'
import { styled, theme } from 'twin.macro'
import createHmac from 'create-hmac'
//
import { breakpoints } from '~utils/media'

const IMGPROXY_URL = `${process.env.IMGPROXY_HOSTNAME || 'https://imgproxy.cyberbus.be'}`

const urlSafeBase64 = (string) =>
  Buffer.from(string).toString('base64').replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')

const hexDecode = (hex) => Buffer.from(hex, 'hex')

const sign = (target) => {
  const hmac = createHmac('sha256', hexDecode(process.env.IMGPROXY_KEY))
  hmac.update(hexDecode(process.env.IMGPROXY_SALT))
  hmac.update(target)
  return urlSafeBase64(hmac.digest())
}

const getMediaUrl = (url) => {
  if (process.env.STORYBOOK_MEDIA_REPLACE && process.env.STORYBOOK_MEDIA_REPLACE === 'false') {
    return url
  }

  const pathNameReplace = process.env.DRUPAL_MEDIA_PATH || ''
  // eslint-disable-next-line no-useless-escape
  const pathName = url.replace(/^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+\//, '/').replace(pathNameReplace, '')

  return process.env.DRUPAL_MEDIA_HOSTNAME ? `${process.env.DRUPAL_MEDIA_HOSTNAME}${pathName}` : url
}

const StyledImage = styled(Img)`
  background-color: ${theme`colors['c-image-container']`};

  height: 100% !important;

  > div {
    padding-bottom: 0px !important;
  }

  img {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
  }
`

const parseExtraParams = (params) => {
  const validParams = ['q', 'g', 'pd', 'bg', 'ex', 'el', 'bl', 'sh']

  const extraParamsArray = Object.keys(params)
    .filter((key) => validParams.includes(key))
    .filter((key) => Object.prototype.hasOwnProperty.call(params, key))
    .map((key) => `${key}:${params[key]}`)

  return extraParamsArray.length ? `${extraParamsArray.join('/')}/` : ''
}

export const getSrcImageFixed = (url, params = {}) => {
  if (!url) {
    return null
  }

  const { w = 1280, h = 600, r } = params

  const rType = `rs:${r || 'fit'}`

  const b64url = Base64.encodeURI(getMediaUrl(url))
  const extraParams = parseExtraParams(params)

  return `${IMGPROXY_URL}/${rType}:${w}:${h}:1/${extraParams}${b64url}`
}

export const getSrcImage = (url) => {
  if (!url) {
    return null
  }

  const b64url = Base64.encodeURI(getMediaUrl(url))

  return `${IMGPROXY_URL}/${b64url}`
}

export const ImageFluid = ({
  url,
  alt = 'null',
  loading = 'lazy',
  fadeIn = true,
  className = '',
  params = {},
  noCrop = false,
  ...rest
}) => {
  if (!url) {
    return null
  }

  const { mw, mh, w, h, ar, r, g } = params

  if (!g) {
    params.g = 'sm'
  }

  const rType = `rs:${r || 'fit'}`
  const maxWidth = mw || w || 1920
  const maxHeight = mh || h || 1280
  const ratio = maxWidth / maxHeight

  const [sWidth, mWidth, lWidth, xlWidth] = breakpoints

  const sHeight = Math.round(sWidth / ratio)
  const mHeight = Math.round(mWidth / ratio)
  const lHeight = Math.round(lWidth / ratio)
  const xlHeight = Math.round(xlWidth / ratio)

  // eslint-disable-next-line no-useless-escape
  const b64url = Base64.encodeURI(getMediaUrl(url))
  const extraParams = parseExtraParams(params)

  const srcSignature = sign(`/${rType}:${maxWidth}:${maxHeight}:1/${extraParams}${b64url}`)
  const srcWebpSignature = sign(`/f:webp/${rType}:${maxWidth}:${maxHeight}:1/${extraParams}${b64url}`)
  const sSignature = sign(`/${rType}:${sWidth}:${sHeight}:1/${extraParams}${b64url}`)
  const sWebpSignature = sign(`/f:webp/${rType}:${sWidth}:${sHeight}:1/${extraParams}${b64url}`)
  const mSignature = sign(`/${rType}:${mWidth}:${mHeight}:1/${extraParams}${b64url}`)
  const mWebpSignature = sign(`/f:webp/${rType}:${mWidth}:${mHeight}:1/${extraParams}${b64url}`)
  const lSignature = sign(`/${rType}:${lWidth}:${lHeight}:1/${extraParams}${b64url}`)
  const lWebpSignature = sign(`/f:webp/${rType}:${lWidth}:${lHeight}:1/${extraParams}${b64url}`)
  const xlSignature = sign(`/${rType}:${xlWidth}:${xlHeight}:1/${extraParams}${b64url}`)
  const xlWebpSignature = sign(`/f:webp/${rType}:${xlWidth}:${xlHeight}:1/${extraParams}${b64url}`)

  const src = `${IMGPROXY_URL}/${srcSignature}/${rType}:${maxWidth}:${maxHeight}:1/${extraParams}${b64url}`
  const srcWebp = `${IMGPROXY_URL}/${srcWebpSignature}/f:webp/${rType}:${maxWidth}:${maxHeight}:1/${extraParams}${b64url}`

  const srcSet = `${IMGPROXY_URL}/${sSignature}/${rType}:${sWidth}:${sHeight}:1/${extraParams}${b64url} ${sWidth}w,
    ${IMGPROXY_URL}/${mSignature}/${rType}:${mWidth}:${mHeight}:1/${extraParams}${b64url} ${mWidth}w,
    ${IMGPROXY_URL}/${lSignature}/${rType}:${lWidth}:${lHeight}:1/${extraParams}${b64url} ${lWidth}w,
    ${IMGPROXY_URL}/${xlSignature}/${rType}:${xlWidth}:${xlHeight}:1/${extraParams}${b64url} ${xlWidth}w`

  const srcSetWebp = `${IMGPROXY_URL}/${sWebpSignature}/f:webp/${rType}:${sWidth}:${sHeight}:1/${extraParams}${b64url} ${sWidth}w,
    ${IMGPROXY_URL}/${mWebpSignature}/f:webp/${rType}:${mWidth}:${mHeight}:1/${extraParams}${b64url} ${mWidth}w,
    ${IMGPROXY_URL}/${lWebpSignature}/f:webp/${rType}:${lWidth}:${lHeight}:1/${extraParams}${b64url} ${lWidth}w,
    ${IMGPROXY_URL}/${xlWebpSignature}/f:webp/${rType}:${xlWidth}:${xlHeight}:1/${extraParams}${b64url} ${xlWidth}w`

  let aspectRatio = ar || 2

  if (mw && mh) {
    const [ratioX, ratioY] = calculateAspectRatio(mw, mh).split(':')
    aspectRatio = (ratioX * 1) / (ratioY * 1)
  } else {
    const [ratioX, ratioY] = calculateAspectRatio(w, h).split(':')
    aspectRatio = (ratioX * 1) / (ratioY * 1)
  }

  const fluid = {
    sizes: `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`,
    src,
    srcWebp,
    srcSet,
    srcSetWebp,
    aspectRatio
  }

  if (noCrop) {
    return <StyledImage fluid={fluid} alt={alt} loading={loading} fadeIn={fadeIn} className={className} {...rest} />
  }

  return <Img fluid={fluid} alt={alt} loading={loading} fadeIn={fadeIn} className={className} {...rest} />
}

export const ImageFixed = ({
  url,
  alt = 'null',
  loading = 'lazy',
  fadeIn = true,
  className = '',
  params = {},
  ...rest
}) => {
  if (!url) {
    return null
  }

  const { w = 540, h = 480, r } = params

  const rType = `rs:${r || 'fit'}`
  const ratio = w / h

  const sWidth = w * 1
  const mWidth = w * 1.5
  const lWidth = w * 2

  const sHeight = h * 1
  const mHeight = Math.round(mWidth / ratio)
  const lHeight = Math.round(lWidth / ratio)

  const b64url = Base64.encodeURI(getMediaUrl(url))
  const extraParams = parseExtraParams(params)

  const srcSignature = sign(`/${rType}:${w}:${h}:1/${extraParams}${b64url}`)
  const srcWebpSignature = sign(`/f:webp/${rType}:${w}:${h}:1/${extraParams}${b64url}`)
  const sSignature = sign(`/${rType}:${sWidth}:${sHeight}:1/${extraParams}${b64url}`)
  const sWebpSignature = sign(`/f:webp/${rType}:${sWidth}:${sHeight}:1/${extraParams}${b64url}`)
  const mSignature = sign(`/${rType}:${mWidth}:${mHeight}:1/${extraParams}${b64url}`)
  const mWebpSignature = sign(`/f:webp/${rType}:${mWidth}:${mHeight}:1/${extraParams}${b64url}`)
  const lSignature = sign(`/${rType}:${lWidth}:${lHeight}:1/${extraParams}${b64url}`)
  const lWebpSignature = sign(`/f:webp/${rType}:${lWidth}:${lHeight}:1/${extraParams}${b64url}`)

  const src = `${IMGPROXY_URL}/${srcSignature}/${rType}:${w}:${h}:1/${extraParams}${b64url}`
  const srcWebp = `${IMGPROXY_URL}/${srcWebpSignature}/f:webp/${rType}:${w}:${h}:1/${extraParams}${b64url}`

  const srcSets = [
    `${IMGPROXY_URL}/${sSignature}/${rType}:${sWidth}:${sHeight}:1/${extraParams}${b64url} 1x`,
    `${IMGPROXY_URL}/${mSignature}/${rType}:${mWidth}:${mHeight}:1/${extraParams}${b64url} 1.5x`,
    `${IMGPROXY_URL}/${lSignature}/${rType}:${lWidth}:${lHeight}:1/${extraParams}${b64url} 2x`
  ]

  const srcSetsWebp = [
    `${IMGPROXY_URL}/${sWebpSignature}/f:webp/${rType}:${sWidth}:${sHeight}:1/${extraParams}${b64url} 1x`,
    `${IMGPROXY_URL}/${mWebpSignature}/f:webp/${rType}:${mWidth}:${mHeight}:1/${extraParams}${b64url} 1.5x`,
    `${IMGPROXY_URL}/${lWebpSignature}/f:webp/${rType}:${lWidth}:${lHeight}:1/${extraParams}${b64url} 2x`
  ]

  if (sWidth < 855) {
    const xlWidth = w * 3
    const xlHeight = Math.round(xlWidth / ratio)

    const xlSignature = sign(`${rType}:${xlWidth}:${xlHeight}:1/${extraParams}${b64url}`)
    const xlWebpSignature = sign(`f:webp/${rType}:${xlWidth}:${xlHeight}:1/${extraParams}${b64url}`)

    /*srcSets.push(`${IMGPROXY_URL}/${xlSignature}/${rType}:${xlWidth}:${xlHeight}:1/${extraParams}${b64url} 3x`)
    srcSetsWebp.push(
      `${IMGPROXY_URL}/${xlWebpSignature}/f:webp/${rType}:${xlWidth}:${xlHeight}:1/${extraParams}${b64url} 3x`
    )*/
  }

  const fixed = {
    width: w,
    height: h,
    src,
    srcWebp,
    srcSet: srcSets.join(','),
    srcSetWebp: srcSetsWebp.join(',')
  }

  return <Img fixed={fixed} alt={alt} loading={loading} fadeIn={fadeIn} className={className} {...rest} />
}

export const ImageProxy = ({ type, width, height, noCrop = false, ...rest }) => {
  if (type === 'fluid') {
    return <ImageFluid noCrop={noCrop} {...rest} />
  }

  return <ImageFixed noCrop={noCrop} {...rest} />
}
